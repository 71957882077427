import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Borra un horario flexible de un trabajador
 * @param {object} data 
 * @param {string} data.id
 * @returns {Promise}
 */
export default async function deleteFlexibleSchedule(data) {
    const response = await apiService.delete('/flexibleSchedules/delete',data);
    return response;
}
