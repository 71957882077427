import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Actualiza uno de los campos de un horario flexible de un trabajador
 * @param {Object} data
 * @param {string} data.id
 * @param {string} data.active_since
 * @param {string} data.active_until
 * @param {int} data.tolerance
 * @param {int} data.direction
 * @param {string} data.comment
 * @returns {Promise}
 */
export default async function updateFlexibleSchedule(data) {
    const response = await apiService.put('/flexibleSchedules/update',data);
    return response;
}