import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Obtiene las tolerancias de los horarios flexibles
 * @returns {Promise}
 */
export default async function getFLexibleSchedulesTolerances() {
    const response = await apiService.get('/flexibleSchedulesTolerances');
    return response;
}
