import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Crea un horario flexible para un trabajador
 * @param {Object} data
 * @param {string} data.contract_id
 * @param {string} data.active_since
 * @param {string} data.active_until
 * @param {int} data.tolerance
 * @param {int} data.direction
 * @param {string} comment
 * @returns {Promise}
 */
export default async function createFlexibleSchedule(data) {
    const response = await apiService.post('/flexibleSchedules',data);
    return response;
}
